import saveAs from 'save-as';
import { parseRule } from './rulesParser.ts';
import rulesParser from './rulesParser.ts';
import { Algo, directionToPrettyString, getDirections, IDLE, prettyView, toCanonical, View } from './types.ts';
import { onError } from './error_panel.ts';
import 'regenerator-runtime/runtime';
import current_version from '../current_version.json';
import loadAlgoFromString from './loader.ts'
import loadAlgoFromStringLegacy from './loader_legacy.ts';
import YAML from 'yaml'
import { algo_model_to_path } from './summary_parser.ts';

import Summary from '../../summary.yaml';
console.log(Summary);

if (process.env.NODE_ENV === 'production') {
    document.getElementById('options-input-link').style.display = 'none';
    document.getElementById('initial-input-link').style.display = 'none';
    document.getElementById('files-tab-link').style.display = 'none';
    document.getElementById('options-input-link').innerHTML = '';
    document.getElementById('initial-input-link').innerHTML = '';
    document.getElementById('files-tab-link').innerHTML = '';
}
else 
{
    const onTabClick = (tab_id) => (event) => {
    
        document.querySelectorAll('.tabs-content').forEach(el => {
            el.style.display = 'none';
        });
        document.querySelectorAll('.tabs > div').forEach(el => {
            el.classList.remove("active");
        });
        document.getElementById(tab_id).style.display = 'block';
        event.target.classList.add("active");
    }
    
    document.querySelectorAll('.tabs > div').forEach(el => {
        console.log(el.getAttribute('data-tab'));
        el.addEventListener('click', onTabClick(el.getAttribute('data-tab')));
    })    
}


console.log('CURRENT_VERSION', current_version);
document.getElementById('version-info').innerHTML = 'version: '+current_version.date;

(async () => {
    if(current_version.date === 'now') return; //["localhost", "127.0.0.1", "[::]"].includes(location.hostname)) return;
    try{
        const content = await (await fetch('https://bramas.pages.unistra.fr/robot-grid-exploration-simulator/current_version.json?'+Math.random())).text();
        const data = JSON.parse(content);
        console.log('PUBLIC CURRENT_VERSION', data);
        if(data.hash != current_version.hash) {
            alert('new version available, please reload the page');
        }
    } catch(e) {
        console.log(e);
    }
})();


ace.config.set("basePath", "https://pagecdn.io/lib/ace/1.4.12/");
let options = ace.edit("options-input");
//options.setTheme("ace/theme/monokai");
options.session.setMode("ace/mode/yaml");
let initial = ace.edit("initial-input");
//initial.setTheme("ace/theme/monokai");
//initial.session.setMode("ace/mode/json");
let rules = ace.edit("rules-input");
//rules.setTheme("ace/theme/monokai");
//rules.session.setMode("ace/mode/json");

let firstCompilation = true;

let rulesDebounceID = null;

const globals = {

}

let lockUpdate = false;

if(process.env.NODE_ENV === 'production') {
    rules.setReadOnly(true);
    initial.setReadOnly(true);
    options.setReadOnly(true);
}


export function reloadAll() {
    if(!globals.onRules) return;

    globals.onOptions(getOptionsText())
    globals.onInitial(getInitialText())
    globals.onRules(getRulesText())
    //console.log(getInitialText())
}


function onRulesChanged(onRules) {
    if(lockUpdate) return;

    rulesDebounceID = setTimeout(() => {
        rulesDebounceID = null;
        let val = rules.getValue();
        localStorage.setItem('rules', val);
        try{
            onRules(val);
        } catch(e) { console.log(e) }
    }, 1000);
}
function onOptionsChanged(onRules, onOptions) {
    if(lockUpdate) return;
    let val = options.getValue();
    localStorage.setItem('options', val);
    try{
        onOptions(val);
        onRules(rules.getValue());
    } catch(e) { console.log(e) }
}

function onInitialChanged(onInitial, options = {}) {
    if(lockUpdate) return;

    let val = initial.getValue();
    localStorage.setItem('initial', val);
    try{
        onInitial(val, options);
    } catch(e) { console.log(e) }
}

export const registerEventListeners = (onRules, onOptions, onInitial) => {
    globals.onRules = onRules;
    globals.onOptions = onOptions;
    globals.onInitial = onInitial;

    rules.on('focus', () => clearMarkers());

    if (process.env.NODE_ENV !== 'production') {
        rules.on('change', (e) => {
            if (rulesDebounceID) 
            {
                clearTimeout(rulesDebounceID);
            }
            if(firstCompilation) {
                firstCompilation = false;
                onOptionsChanged(onRules, onOptions);
                onInitialChanged(onInitial);
            }
            onRulesChanged(onRules);
        })
        options.on('change', (e) => {
            onOptionsChanged(onRules, onOptions);
        })
        initial.on('change', (e) => {
            onInitialChanged(onInitial);
        })
    }
    
    if(location.search.length > 1){
        let algoUrl;
        if(location.search.slice(1).split('/').length == 2) {
            const [paperName, algoIdx] = location.search.slice(1).split('/');
            if(Summary.papers[paperName])
            {    
                let idx = -1;
                if(!isNaN(parseInt(algoIdx)) && Summary.papers[paperName].algos[parseInt(algoIdx)] !== undefined) {
                    idx = parseInt(algoIdx);
                } else {
                    for(let i = 0; i < Summary.papers[paperName].algos.length; i++) {
                        if(Summary.papers[paperName].algos[i].alias == algoIdx) {
                            idx = i;
                            break;
                        }
                    }
                }
                if(idx != -1) {
                    algoUrl = algo_model_to_path(Summary,
                        [
                            ...Summary.default,
                            ...(Summary.papers[paperName].type || []),
                            ...Summary.papers[paperName].algos[idx].type,
                        ])+'.web-algo';
                    console.log({algoUrl});
                }
            }
        }
        if(process.env.NODE_ENV !== 'production')
        {
            if(!algoUrl) {
                algoUrl = location.search.slice(1);
            }
        }
        if(!algoUrl) {
            onError('Welcome to the 3D robot simulator. \n\nThe algorithm you provided in the url cannot be found.')
        } else {
            onError('Welcome to the 3D robot simulator. \n\nThe algorithm is loading, you have to wait.\n\nIn case of error, please reload the page.')
            setTimeout(() => {
                loadFromUrl(algoUrl)
            }, 100);
        }
    }
}


export function getRulesText() {
    return rules.getValue();
}
export function getInitialText() {
    return initial.getValue();
}
export function getOptionsText() {
    return options.getValue();
}


ace.config.setModuleUrl('ace/mode/custom_rules', 'rulesMode.js');

rules.session.setMode('ace/mode/custom_rules')
ace.config.set('basePath', './');
rules.setTheme("ace/theme/rules");



const saveButton = document.getElementById('save-button')
const loadButton = document.getElementById('file-input')
const urlLoadInput = document.getElementById('url-load-input')
const urlLoadSubmit = document.getElementById('url-load-submit')
const checkUnusedButton = document.getElementById('check-unused')


const exportLatexButton = document.getElementById('export-latex')
const exportV1Button = document.getElementById('export-v1')
exportV1Button.style.display = 'none';

saveButton.addEventListener('click', () => {
    /*
    var blob = new Blob([JSON.stringify({
        initial: initial.getValue(),
        options: options.getValue(),
        rules: rules.getValue()
    })], {type: "text/json;charset=utf-8"});
    */
    var blob = new Blob([`
****** OPTIONS ******
${YAML.stringify({
    version: 1,
    ...YAML.parse(options.getValue())
})}

****** INITIAL CONFIGURATIONS ******
${initial.getValue()}

****** RULES ******
${rules.getValue()}
`], {type: "text/json;charset=utf-8"});
    saveAs(blob, "algo.web-algo");
})

exportLatexButton.addEventListener('click', () => {

    if(!algo) return;

    const parsed = rulesParser(rules.getValue(), algo.options.visibilityRange, algo.options.dimension);

    const exportLines = [];

    for(let r of Object.values(parsed.rules))
    {   
        let tmp = []
        for(const dir of getDirections(algo.options.visibilityRange))
        {
            if(dir.z != 0 && algo.options.dimension == 2) {
                continue;
            }
            const formatSpace = (dir.x <= 0 && -dir.x+Math.abs(dir.y) == algo.options.visibilityRange ? ' ':'');
            let state = r.view.get(dir);
            if(r.view.hasWallsBoundaries()) {
                if(state != 'W')
                {
                    if(r.view.wallsBoundaries.wallX[0] && dir.x <= r.view.wallsBoundaries.wallX[0])
                        state = '|';
                    if(r.view.wallsBoundaries.wallX[1] && dir.x >= r.view.wallsBoundaries.wallX[1])
                        state = '|';
                
                    if(r.view.wallsBoundaries.wallY[0] && dir.y <= r.view.wallsBoundaries.wallY[0])
                        state = (state == '|' ? '+' : '-');
                    if(r.view.wallsBoundaries.wallY[1] && dir.y >= r.view.wallsBoundaries.wallY[1])
                        state = (state == '|' ? '+' : '-');
                    
                    if(r.view.wallsBoundaries.wallZ[0] && dir.z <= r.view.wallsBoundaries.wallZ[0])
                        state = (state == '|' ? '+' : '-');
                    if(r.view.wallsBoundaries.wallZ[1] && dir.z >= r.view.wallsBoundaries.wallZ[1])
                        state = (state == '|' ? '+' : '-');
                }
                
            }
            tmp.push(formatSpace + state);
        }
        exportLines.push('\\algoStep{'+
            tmp.join(',')+'}{'+
            r.destination.dir.map(d => directionToPrettyString(d)).join(',')+'}'+
            (r.destination.color != r.view.getCenter() ? '['+r.destination.color+']' : '')
        )
    }

    // save in the clipboard
    const el = document.createElement('textarea');
    el.value = exportLines.join('\n');
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);

    //var blob = new Blob([exportLines.join('\n')], {type: "text/json;charset=utf-8"});
    //saveAs(blob, "algo.tex");

});

loadButton.addEventListener('input', (event) => {
    var file = event.target.files[0];
    if (!file) {
        console.log('no file')
        return;
    }
    var reader = new FileReader();
    reader.onload = function(e) {
        var content = e.target.result;
        loadFromString(content);
    };
    reader.readAsText(file);
});

function loadFromString(content: string) {

    let algoInfo;
    try{
        algoInfo = loadAlgoFromString(content);
    } catch(e) {
        try {
            const data = YAML.parse(content);
            algoInfo = loadAlgoFromStringLegacy(data);
        } 
        catch(e2) 
        {
            // ignore error
            // let the user retry
            console.log(e, e2);
            alert('there was an error, please retry!');
            throw new Error('Error while parsing');
        }
    }

    initial.session.setValue(algoInfo.initialConfigurations);
    options.session.setValue(algoInfo.options);
    rules.session.setValue(algoInfo.rules);
    
    initial.session.selection.clearSelection();
    options.session.selection.clearSelection();
    rules.session.selection.clearSelection();
    reloadAll();
}

async function loadFromUrl(url) {
    const content = await (await fetch(url+'?'+Math.random())).text();
    loadFromString(content);
}

urlLoadSubmit.addEventListener('click', (event) => {
    event.preventDefault();
    const algoUrl = urlLoadInput.value;
    loadFromUrl(algoUrl)
});





let algo : Algo | null = null
export const initLogPanel = (_algo: Algo) => {
    algo = _algo;
}

const logPanel = document.getElementById('log-panel');
export const appendLog = (msg) => {
    let el = document.createElement("div");
    //el.innerHTML = `<textarea onclick="this.select()" class="view" readonly="readonly" rows="5" cols="5">${msg}</textarea>`;
    el.innerHTML = `<div>${msg}</div>`;
    logPanel.prepend(el);
}
export const logAppendView = (view: View) => {
    if(!algo) return;
    clearMarkers();

    const {rule, transformation} = toCanonical({
        view, destination: {dir: [IDLE], color: view.getCenter()}
    }, algo.options);

    const key = rule.view.toString();

    if(algo.rules[key]) {
        const ref = algo.rulesReference[key];
        let el = document.createElement("div");
        el.innerHTML = `<div>Rule defined at line ${ref.line}</div>`;
        logPanel.prepend(el);
        rules.scrollToLine(ref.line, true, true, function () {});
        rules.gotoLine(ref.line, 0, true);
        var Range = ace.require('ace/range').Range;
        rules.session.addMarker(new Range(ref.line-1, 0, ref.line_end, 0), "myMarker", "fullLine");
    } else {
        let el = document.createElement("div");
        el.innerHTML = `Rule not yet defined:<br/><textarea onclick="this.select()" class="view" readonly="readonly" rows="5" cols="5">${prettyView(view, algo.options.dimension, {dest: 'idle'})}</textarea>`;
        logPanel.prepend(el);
    }
}

checkUnusedButton.addEventListener('click', () => {
    if(!algo) return;
    const unused = new Set();
    let firstLines = null;
    Object.values(algo.rulesReference).forEach(({view, line, line_end}) => {
        if(!algo.usedViews.has(line)) {
            unused.add(view.toString()+' lines '+line+':'+line_end);
            if(!firstLines) firstLines = {line, line_end};
        }
    });
    appendLog('unused views:<br/>'+Array.from(unused.values()).join('<br/>'));
    if(firstLines)
    {
        rules.scrollToLine(firstLines.line, true, true, function () {});
        rules.gotoLine(firstLines.line, 0, true);
        var Range = ace.require('ace/range').Range;
        rules.session.addMarker(new Range(firstLines.line-1, 0, firstLines.line_end, 0), "myMarker", "fullLine");
    }
});

function clearMarkers() {
    const prevMarkers = rules.session.getMarkers();
    if (prevMarkers) {
        const prevMarkersArr = Object.keys(prevMarkers);
        for (let item of prevMarkersArr) {
            rules.session.removeMarker(prevMarkers[item].id);
        }
    }
}

const strDistance = (str1 = '', str2 = '') => {
    let distance = 0;
    if(str1.length === str2.length) {
       for (let i = 0; i < str1.length; i++) {
          if (str1[i] !== str2[i]){
             distance++
          }
       }
       return distance
    };
    return -1;
 };
export const initDebugPanel = (algo: Algo) => {
    const buttonEl = document.getElementById('search-rules-button');
    const inputEl = document.getElementById('search-rules-input');
    const resultsEl = document.getElementById('search-rules-results');
    if(!buttonEl || !inputEl || !resultsEl) return;

    buttonEl.addEventListener('click', () => {
        const {view, destination: {dir, color}} = 
            parseRule(inputEl.value.split('\n'), 0,  algo.options.visibilityRange, algo.options.dimension)
        console.log(0,prettyView(view, algo.options.dimension).replaceAll(/[ \t\n]/g, ''));
        Object.values(algo.rules).forEach(r => {
            const d = strDistance(
                prettyView(r.view, algo.options.dimension).replaceAll(/[ \t\n]/g, ''),
                prettyView(view, algo.options.dimension).replaceAll(/[ \t\n]/g, '')
                );
            if(d == 0 || d == 1) {
                console.log(d,prettyView(r.view, algo.options.dimension).replaceAll(/[ \t\n]/g, ''));
            }
        });
    })
}




if (process.env.NODE_ENV !== 'production') {

if(localStorage.getItem('rules')) {
    rules.setValue(localStorage.getItem('rules'));
} else {
    rules.setValue(`
.
.
.FL -> right
.
.

.
.
FL. -> right
.
.
`);
}
if(localStorage.getItem('options')) {
    options.setValue(localStorage.getItem('options'));
} else {
    options.setValue(`
walls: [[0,0,0],[6,6,6]]
chirality: true
visibilityRange: 1
dimension: 3
`)
}
if(localStorage.getItem('initial')) {
    initial.setValue(localStorage.getItem('initial'));
} else {
    initial.setValue(`
.

.
A
..LF
`)
}
}



